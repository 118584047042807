import { create } from "zustand";
import socketIO from "../utils/socket";

export type ISocketStore = {
    socket: socketIO;
    setSocket:(socket:socketIO)=>void;
};

const useSocketStore = create<ISocketStore>((set, get) => ({
    socket: {} as socketIO,
    setSocket: (socket:socketIO) => set(() => ({socket})),
  }));
  
  export default useSocketStore;