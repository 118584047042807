import { Card } from "antd";
import React, { useRef } from "react";
import Socket, { io } from "socket.io-client";
import AudienceChart from "./charts";

export default function Engagement({ roomId }: any) {
  const token = localStorage.getItem("AUTH_TOKEN");
  const [client, setClient] = React.useState<any>(null);
  const [count, setCount] = React.useState<any>();
  const interval = useRef<NodeJS.Timer>()
  React.useEffect(() => {
    const client = io("https://cs.kgs.live", {
      auth: { token },
      query: {
        room: roomId,
        isAdmin: true,
      },
    });

    setClient(client);
    interval.current = setInterval(() => {
      setClient((c: any) => {
        if (roomId) {
          client.emit("getAudience", { room: roomId }, (count: any) => {
            setCount({
              date: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
              stars: count,
            });
          });
        }
        return client;
      });
    }, 5000);

    return () => {
      clearInterval(interval.current);
      client.removeListener();
      client.disconnect();
    };
  }, []);

  React.useEffect(() => {}, [client]);

  return (
    <Card style={{ height: 400 }}>
      <AudienceChart count={count} />
    </Card>
  );
}
