import { message } from "antd";
import moment from "moment";
import { RTMP_SERVER } from "../api";
import { IBucketStore } from "../store";
import { IConfig } from "../interfaces";

export const getStreamKey = (vid:number | string) => 
{
    const unix = moment().unix();
    return `kgss_${unix}_v${vid}`;
}

export const urlToCDN = (url:string,buckets:IConfig[]):string => {
    if(url=="") {
      message.error("Please enter url")
      return ""
    } 
    if (url.match(RTMP_SERVER)) {
      return url.replace(
        RTMP_SERVER,
        localStorage.getItem("live_CDN") || ""
      );
    }
    try{
        const uri = new URL(url);
        const baseUrl = uri.origin;
        let cdnUrl = ""
        const bucket = uri.pathname.split("/")[1];
        buckets.forEach((element) => {
          if (element.url == baseUrl && element.bucket == bucket) {
            cdnUrl = element.cdn_url + uri.pathname
            return;
          }
        });
        return cdnUrl
    }catch(error:any){
      return url
    }
  };