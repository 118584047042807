import {
  Outlet,
  RootRoute,
  Route,
  Router,
  redirect,
} from "@tanstack/react-router";
import DashboardLayout from "./layouts/appLayout";
import Config from "./screens/config";
import Schedules from "./screens/schedules";
import Login from "./screens/auth/login";
import GoLive from "./screens/goLive";
import Studio from "./screens/studio";
import List from "./screens/list";
import Layout from "antd/es/layout/layout";
import Tools from "./screens/tools";
import LandingPage from "./screens";
import { loginRequest } from "./api";
const beforeLoad = async () => {
  const token = localStorage.getItem("AUTH_TOKEN");
  if (!token) {
    throw redirect({
      to: "/login",
      search: {
        redirect: router.state.location.href,
      },
    });
  }
  loginRequest()
};

const rootRoute = new RootRoute({
  component: () => (
    <Layout>
      <DashboardLayout defalutSelectedKey={["1"]}>
        <Outlet />
      </DashboardLayout>
    </Layout>
  ),
});

const loginRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "login",
  component: () => <Login />,
});

// const dashboardRoute = new Route({
//   getParentRoute: () => rootRoute,
//   path: "/",
//   component: (params) => {
//     // if (!isAuthenticated()) {
//     //   return <Login />
//     // }
//     return (
//       <DashboardLayout defalutSelectedKey={["1"]}>
//         <Outlet />
//       </DashboardLayout>
//     )
//   },
// });

const statisticRoute = new Route({
  getParentRoute: () => rootRoute,
  beforeLoad,
  path: "/",
  id:'authentication',
  component: () => {
    return <LandingPage />
  }
})

const schedulesRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "schedules",
  beforeLoad,
  component: () => <Schedules />,
});

const goLiveRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "go-live",
  beforeLoad,
  component: () => <GoLive />,
});

const studioRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "studio",
  component: () => <Studio />,
});
const listRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "list",
  beforeLoad,
  component: () => <List type="" />,
});
const youtubeRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "youtube",
  beforeLoad,
  component: () => <List type="yt" />,
});

const liveRoute = new Route({
  getParentRoute: () => studioRoute,
  path: "$slug",
  component: () => <Studio />,
});

const configRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/config",
  beforeLoad,
  component: () => <Config />,
});
const toolsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/tools",
  beforeLoad,
  component: () => <Tools />,
});

const routeTree = rootRoute.addChildren([
  studioRoute.addChildren([liveRoute]),
  loginRoute,
  statisticRoute,
  listRoute,
  configRoute,
  schedulesRoute,
  goLiveRoute,
  toolsRoute,
  youtubeRoute
]);


const router = new Router({
  routeTree,
  defaultPreload: "intent",
});


export default router;
