import { Button, Descriptions, Select, Tooltip, message } from "antd";
import api, { BROADCAST_URL, RTMP_SERVER } from "../../api";
import useClipboard from "../../utils";
import useBucketStore from "../../store";
import { IVideo } from "../../interfaces";
import { CopyOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { ICourse } from "../../screens/list";
import moment from "moment";
export type IPLayer = 'old'|"new"
export type IURL = { label: string; value: string };

interface IProps {
  player: "old" | "new";
  setPlayer: (player: "old" | "new") => void;
  latest: IVideo;
  course: ICourse;
  playerUrls: IURL[];
  onChangeUrl: any;
  activeUrl: IURL;
}

export default function VideoDetail({
  player,
  setPlayer,
  latest,
  course,
  playerUrls,
  activeUrl,
  onChangeUrl,
}: IProps) {
  const { copy } = useClipboard();
  const { buckets } = useBucketStore();
  const [messageApi, context] = message.useMessage();
  const handleChangeBucket = async (bucket: string) => {
    if (latest.status == "rendering" || latest.status == "rendered") {
      return message.error(
        "sorry u can't change bucket becouse class is rendring"
      );
    }
    try {
      const res = await api.put(`/video/${latest._id}`, { bucket });
      messageApi.success("bucket updated successfully");
    } catch (e) {
      console.log({ e });
    }
  };

  if (!latest._id || !activeUrl?.value) return <div />;

  return (
    <>
      {context}
      <Descriptions layout="vertical" size="small" bordered={true}>
      <Descriptions.Item label="Change Player" span={4}>
          <Select
            defaultValue={activeUrl.value}
            showSearch
            value={player}
            style={{ width: "100%" }}
            onSelect={(value:any)=>{
              setPlayer(value)
            }
              }
            options={[
              {
                label:'New Player',
                value:'new'
              },
              {
                label:'Old Player',
                value:'old'
              }
            ] }
          />
        </Descriptions.Item>
        <Descriptions.Item label="Change Video" span={4}>
          <Select
            defaultValue={activeUrl.value}
            showSearch
            style={{ width: "100%" }}
            onSelect={onChangeUrl}
            options={playerUrls}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Title">{latest.title}</Descriptions.Item>
        <Descriptions.Item label="Scheduled At" span={3}>
          {moment(latest.schedule_at).format("DD-MM-YY HH:SS")}
        </Descriptions.Item>
        <Descriptions.Item label="Course" span={3}>
          {course?.title}
        </Descriptions.Item>
        <Descriptions.Item label="Stream key" span={3}>
          <span>{latest.stream_key}</span>
          <Tooltip title="Click to copy">
            <Button
              onClick={() => copy(latest.stream_key, "Stream key copied")}
              type="dashed"
              style={{ marginLeft: 5 }}
              icon={<CopyOutlined />}
            />
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label="Broadcast url" span={3}>
          <span>{BROADCAST_URL}</span>
          <Tooltip title="Click to copy">
            <Button
              onClick={() => copy(BROADCAST_URL, "Broadcast key copied")}
              type="dashed"
              style={{ marginLeft: 5 }}
              icon={<CopyOutlined />}
            />
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label="Stream URL" span={3}>
          <span>
            {RTMP_SERVER}/hls/{latest.stream_key}.m3u8
          </span>
          <Tooltip title="Click to copy">
            <Button
              onClick={() =>
                copy(
                  `${RTMP_SERVER}/hls/${latest.stream_key}.m3u8`,
                  "Stream Url copied"
                )
              }
              type="dashed"
              style={{ marginLeft: 5 }}
              icon={<CopyOutlined />}
            />
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label="Selected Bucket" span={3}>
          <Select
            defaultValue={latest.bucket}
            showSearch
            placeholder="Select where to store"
            style={{ width: "100%" }}
            onSelect={(e) => handleChangeBucket(e)}
            options={buckets?.map((val) => ({
              value: val?._id,
              label: val?.title,
            }))}
          />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}

const Title = styled.div`
  font-size: 20px;
  margin: 5px;
  color: #4549c4;
`;
