import { Skeleton } from "antd";
import { PLAYER_URL, PLAYER_URL_NEW } from "../../api";

interface IProps {
  height?: number;
  videoUrl?: string;
  key?: number;
  player: "old" | "new";
}

export default function StudioPlayer({
  height = 420,
  videoUrl,
  key = 0,
  player,
}: IProps) {
  if (!videoUrl) {
    return <Skeleton active />;
  }
  return (
    <iframe
      title="KGS Player"
      height={height}
      width={"100%"}
      key={key}
      frameBorder={0}
      style={{
        borderRadius: 10,
        padding: 5,
      }}
      src={`${
        player === "new" ? PLAYER_URL_NEW : PLAYER_URL
      }player?src=${videoUrl}`}
    ></iframe>
  );
}
