import { Card, Col, Row, Statistic } from "antd";
import { ADMIN_BASE_URL } from "../api";
import moment from "moment";
import { useEffect, useState } from "react";
import { useApi, useMount } from "../hooks";
import { ISchedule, TStatic } from "../interfaces";

export default function LandingPage() {
  const [length, setLength] = useState(0);
  const { data, load } = useApi<TStatic>("video/statistic");
  const { data: schedules, load: getSchedule } = useApi<ISchedule[]>(
    ADMIN_BASE_URL + `schedules`
  );
  useMount(() => {
    load();
    getSchedule();
  });
  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    const number = schedules?.filter((item) => {
      return moment(item.published_at).format("YYYY-MM-DD") === currentDate;
    });
    setLength(number?.length || 0);
  }, [schedules]);

  const StaticCard = ({title,value=0}:{title:string,value?:number})=>{
    return (
      <Col xs={20} sm={20} md={10} lg={7} xl={7} style={{ marginTop: 30, marginLeft: 30 }}>
      <Card
        bordered={false}
        style={{
          height: 200,
          textAlign: "center",
          backgroundColor: "white",
        }}
      >
        <Statistic
          title={<span style={{ fontSize: "30px", color: "black" }}>{title}</span>}
          value={value}
          valueStyle={{ color: "#3F8600", fontSize: "50px", margin: 0 }}
        />
      </Card>
    </Col>
    )
  }
  return (
    <div style={{ backgroundColor: "#F0F2F5" ,height: '100%',overflow:"hidden" }}>
      <Row gutter={16}>
        <StaticCard title="Total Schedules" value= {length}/>
        <StaticCard title="Total Live Via Studio" value={data?.total}/>
        <StaticCard title="Total Rendered" value={data?.totalRendered}/>
        <StaticCard title="Active Live" value={data?.totalLive}/>
        <StaticCard title="Rendering" value={data?.totalRendering}/>
        <StaticCard title="Failed To Rendered" value={data?.totalFailed}/>
      </Row>
    </div>
  );
}
