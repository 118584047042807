import { CopyOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { RTMP_SERVER } from "../../api";
import useClipboard from "../../utils";
import { IVideo } from "../../interfaces";

const VideoLink = ({ url, title }: { url: string; title?: string }) => {
  const { copy } = useClipboard();
  return (
    <div>
      <span>{title || url}</span>
      <Tooltip title="Click to copy">
        <Button
          onClick={() => copy(url, "Url copied")}
          type="dashed"
          style={{ marginLeft: 5 }}
          icon={<CopyOutlined />}
        />
      </Tooltip>
    </div>
  );
};

export default function LinkItem(_: string, data: IVideo) {
  const getUrl = (video: any) => {
    if (video.status === "rendered") {
      return `${video.cdnUrl || video.url}/${video.stream_key}.m3u8`;
    }
    return `${localStorage.getItem("live_CDN") || RTMP_SERVER}/hls/${
      video.stream_key
    }.m3u8`;
  };

  if (data.videoType === "yt") return <VideoLink url={data.url} />;

  return (
    <>
      {data.status !== "schedule" ? (
        <VideoLink
          url={getUrl(data)}
          title={getUrl(data).slice(0, 30) + "..." + getUrl(data).slice(-10)}
        />
      ) : (
        <div>link will be available once video will live ...</div>
      )}
    </>
  );
}