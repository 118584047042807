import { useState, useCallback, } from "react";
import { AxiosError,  } from "axios";
import api from "../api";
import { message } from "antd";

export interface ApiResponse<T> {
  result?: T | undefined;
  error?: Error | undefined;
  isFetching: boolean;
}

type TUseApiResponse<T> = {
  load: (...args: any[]) => Promise<void>;
  data: T | undefined;
  error: AxiosError | unknown;
  isLoading: boolean;
  updateData:React.Dispatch<React.SetStateAction<T | undefined>>
};

export default function useApi<T>(url:string,deps:any[]=[]):TUseApiResponse<T>{
  const [data, setData] = useState<T | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<
    AxiosError<unknown, any> | undefined | unknown
  >(undefined);
    const handleMount =useCallback(
          async (params:object) => {
            setError(undefined);
            setIsLoading(true);
            try {
              const result = await api.get(url,{params});
              setData(result.data);
            } catch (error:any) {
              message.error(error?.message)
              // setError(error);
            }
            setIsLoading(false);
          },
          [url]
        );

  return {load:handleMount,data,isLoading,error,updateData:setData}
}