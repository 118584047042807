import {
	Button,
	Card,
	Col,
	Input,
	Row,
	Space,
	Tooltip,
	Typography,
	UploadProps,
	message,
	List,
} from 'antd';
// import Search from 'antd/es/input/Search';
import { useEffect, useState } from 'react';
// import useBucketStore from '../store';
import api, { RTMP_SERVER } from '../api';
import { CopyOutlined, InboxOutlined } from '@ant-design/icons';
import useClipboard from '../utils';
import { RcFile } from 'antd/es/upload';
import { useStorage } from '../hooks/useStorage';
const { Title } = Typography;
export default function Tools() {
	const [url, setUrl] = useState<string>('');
	const [file, setFile] = useState<RcFile | null>(null);
	const { copy } = useClipboard();
	const [cdnHistory, setCdnHistory] = useStorage<string[]>('CDN_HISTORY', []);

	// const props: UploadProps = {
	// 	name: 'file',
	// 	multiple: false,
	// 	beforeUpload(file) {
	// 		const mp4 = file.type.startsWith('video/mp4');
	// 		if (!mp4) {
	// 			message.error('You can only upload mp4 files!');
	// 			return mp4;
	// 		}
	// 		setFile(file);
	// 		return false;
	// 	},
	// 	onDrop(e) {},
	// };

	const urlConvert: React.MouseEventHandler<HTMLElement> = async () => {
		try {
			const response = await api.get(
				`https://admin.khanglobalstudies.com/studio/get-cdn-url?url=${url}`
			);
			message.success('URL Converted', 5);
			setCdnHistory([response.data.cdn, ...cdnHistory.slice(0, 4)]);
		} catch (error) {
			message.error('Invalid URL', 10);
		}
	};



	return (
		<Card title="Tools and utility">
			<Row gutter={16}>
				<Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} className="mb-2">
					<Card title="Url to CDN converter">
						<Space.Compact style={{ width: '100%' }}>
							<Input
								placeholder="Enter valid video url"
								value={url}
								onChange={(event) => setUrl(event.target.value)}
							/>
							<Button
								loading={false}
								onClick={urlConvert}
								type="primary"
							>
								Convert
							</Button>
						</Space.Compact>
						{cdnHistory.map((history) => (
							<Space.Compact style={{ width: '100%' }}>
								<Title level={5}>{history}</Title>
								<Tooltip title="Click to copy">
									<Button
										onClick={() =>
											copy(history, 'Url copied')
										}
										type="dashed"
										style={{ marginTop: 20, marginLeft: 5 }}
										icon={<CopyOutlined />}
									/>
								</Tooltip>
							</Space.Compact>
						))}
					</Card>
				</Col>
				{/* <Col span={12} className="mb-2">
          <Card title="Url to CDN converter">
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from
                uploading company data or other banned files.
              </p>
            </Dragger>
            {file && (
              <Button style={{ width: "100%", margin: "10px 0" }}>
                upload File
              </Button>
            )}
          </Card>
        </Col> */}
			</Row>
		</Card>
	);
}
