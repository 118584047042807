import {
  Button,
  Card,
  Col,
  Descriptions,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
} from "antd";
// import useBucketStore from "../../store";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  FileAddOutlined,
} from "@ant-design/icons";
import api from "../../api";
import { useEffect, useState } from "react";
import { IConfig, configType } from "../../interfaces";
import useBucketStore from "../../store";

const { confirm } = Modal;
const initialValues = {
  _id: "",
  access_key: "",
  secret_key: "",
  region: "",
  bucket: "",
  url: "",
  title: "",
  cdn_url:""
};


export default function BucketDetailCard() {
  const {buckets,addBucket,removeBucket} = useBucketStore()
  const [open, setOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const newBucket = useState<IConfig>(initialValues);
  
  const handleDeleteBucket = (id: string | undefined) => {
    if (!id) return;
    confirm({
      title: "Do you Want to delete these bucket?",
      icon: <ExclamationCircleFilled />,
      content: "This gonna clear all buckets details!",
      onOk() {
        deleteBucket(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const updateBucket = async (values: IConfig) => {
    if (!values._id) return console.log("bucket id not found");
    const url = values.url?.replace(/\/$/, "");
    try {
      const { status, data } = await api.put<IConfig>(`/cred/${values._id}`, {
        ...values,
        url,
      });
      if (status == 200) {
        removeBucket(values._id!);
        addBucket(data);
      }
      newBucket[1](initialValues);
      setOpen(false);
      setIsEditable(false);
    } catch (e: any) {
        // console.log("error",{e})
      // setError(e.message);
      newBucket[1](initialValues);
      setOpen(false);
      setIsEditable(false);
      setOpen(false);
    }
  };

  const deleteBucket = async (id: string | undefined) => {
    if (!id) return;
    const { status } = await api.delete("cred/" + id);
    if (status === 200) {
      removeBucket(id)
    }
  };

  const handleAddBucket = async (values: IConfig) => {
    try {
      const url = values.url?.replace(/\/$/, "");
      const { status, data } = await api.post<IConfig>("/cred", {
        ...values,
        url,
      });
      if (status == 201) {
        addBucket(data)
        setOpen(false);
      }
    } catch (e: any) {
      setOpen(false);
    }
  };

  return (
    <Row gutter={16}>
      {buckets.map((config, i) => (
        <Col xs={24} sm={24} md={24} lg={24} xl={12}  key={i} className="mb-2">
          <Card
            title={config.title || "New Config"}
            extra={
              <>
                <Button
                  danger
                  onClick={() => {
                    setIsEditable(true);
                    setOpen(true);
                    newBucket[1](config);
                  }}
                >
                  <EditOutlined />
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  onClick={() => {
                    handleDeleteBucket(config._id);
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </>
            }
          >
            <Descriptions title="" bordered size="small">
              <Descriptions.Item span={2} label={"ID"}>
                {" "}
                {config._id}{" "}
              </Descriptions.Item>
              <Descriptions.Item span={2} label={"Region"}>
                {" "}
                {config.region}{" "}
              </Descriptions.Item>
              <Descriptions.Item span={2} label={"Bucket"}>
                {" "}
                {config.bucket}{" "}
              </Descriptions.Item>
              <Descriptions.Item span={2} label={"Url"}>
                {" "}
                {config.url}{" "}
              </Descriptions.Item>
              <Descriptions.Item span={2} label={"CDN Url"}>
                {" "}
                {config.cdn_url}{" "}
              </Descriptions.Item>
              <Descriptions.Item span={2} label={"Access Key"}>
                {" "}
                {config.access_key}{" "}
              </Descriptions.Item>
              <Descriptions.Item span={2} label={"Secret Key"}>
                {" "}
                {"*****************************"}{" "}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      ))}
      <Col span={24} className="bg-muted p-1 mt-3">
        <Button type="primary" onClick={() => setOpen(!open)}>
          <FileAddOutlined /> New Bucket
        </Button>
        <ConfigModal
          onSave={isEditable ? updateBucket : handleAddBucket}
          isEditable
          onCancel = {()=>{
            newBucket[1](initialValues)
            setOpen(false)
          }}
          bucket={newBucket}
          open={open}
          triggerModal={(open) => setOpen(open)}
        />
      </Col>
    </Row>
  );
}

interface IProps {
  onSave: (values: IConfig) => void;
  values?: IConfig;
  open: boolean;
  isEditable: boolean;
  onCancel:()=>void;
  bucket: [IConfig, React.Dispatch<React.SetStateAction<IConfig>>];
  triggerModal: (open: boolean) => void;
}

function ConfigModal({ bucket,onCancel, ...props }: IProps) {
  const [form] = Form.useForm<IConfig>();

    useEffect(()=>{
      form.setFieldsValue(bucket[0])
    },[props.open])

  return (
    <Drawer
      // key={JSON.stringify(bucket[0])} 
      title={props.isEditable ? "Update Bucket" : "+ Add New Bucket"}
      width="50%"
      open={props.open}
      closable
      onClose={() => {
        form.resetFields()
        onCancel()
      }}
      footer={null}
    >
       <Form
          form={form}
          onValuesChange={(changedvalue, allvalues) => {
            bucket[1]((prev) => ({ ...prev, ...allvalues }));
          }}
          initialValues={bucket[0]}
          onFinish={() => {
            props.onSave(bucket[0]);
            form.resetFields();
          }}
          layout="vertical"
          onFinishFailed={() => {}}
        >
          <Form.Item<configType>
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: "Please give title to your config!",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item<configType>
            label="Access Key"
            name="access_key"
            rules={[{ required: true, message: "Please input access key!" }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item<configType>
            label="Secret Key"
            name="secret_key"
            rules={[{ required: true, message: "Please input secret key!" }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item<configType>
            label="Region"
            name="region"
            rules={[{ required: true, message: "Please input region !" }]}
          >
            <Input size="large" />
          </Form.Item>
  
          <Form.Item<configType>
            label="Bucket Name"
            name="bucket"
            rules={[
              {
                required: true,
                message: "Please input bucket name!",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item<configType> label="Url" name="url">
            <Input size="large" />
          </Form.Item>
          <Form.Item<configType> label="CDN Url" name="cdn_url" rules={[
              {
                required: true,
                message: "Please input bucket name!",
              },
            ]}>
            <Input size="large" />
          </Form.Item>
  
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {props.isEditable ? "Update Now" : "+ Add Now"}
            </Button>
          </Form.Item>
        </Form>
    </Drawer>
  );
}
