import { Badge, Card, Col, Space, Tag } from "antd";
import { IVideo } from "../../interfaces";
import VideoDetail, { IURL } from "./VideoDetail";
import StudioPlayer from "./StudioPlayer";
import ActionButton from "./ActionButton";
import { ICourse } from "../../screens/list";
import { RTMP_SERVER } from "../../api";
import { useState } from "react";
import { useMount } from "../../hooks";
import { statusColor } from "../../utils/constants";
import Engagement from "../../utils/engagement";

type TProps = {
  video: IVideo;
  onDone: (video: IVideo) => void;
  frameKey: number;
  course: ICourse;
  player: "old" | "new";
  setPlayer: (player: "old" | "new") => void;
};

function LiveStudio({ video, onDone, frameKey, course,player,setPlayer }: TProps) {
  const [playerUrls, setPlayerUrls] = useState<IURL[]>([]);
  const [activeUrl, setActiveUrl] = useState(playerUrls[0]);

  useMount(() => {
    const urls = [
      {
        label: "RTMP Video",
        value: `${localStorage.getItem("live_CDN") || RTMP_SERVER}/hls/${
          video.stream_key
        }.m3u8&is_live=1`,
      },
    ];
    setPlayerUrls(urls);
    setActiveUrl(urls[0]);
  }, [video?.stream_key]);

  return (
    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ overflow:"scroll", height:"100vh" }} >
      <Card
        title={`# ${video?.vid} Video Details`}
        type="inner"
        style={{overflow:"hidden",marginBottom:10}}
        extra={
          <Space>
            <Tag color={statusColor[`${video.status}`]}>
              <Badge
                color={
                  video.status === "rendering"
                    ? "orange"
                    : video.status === "live"
                    ? "green"
                    : "#A9A9A9"
                }
                text={video.status}
              />
            </Tag>
            <ActionButton onDone={onDone} video={video} />
          </Space>
        }
      >
        {video.stream_key !== undefined && (
          <StudioPlayer
            player={player}
            key={frameKey}
            videoUrl={activeUrl.value}
          />
        )}
        {video.stream_key && (
          <VideoDetail
            player={player}
            setPlayer={setPlayer}
            playerUrls={playerUrls}
            activeUrl={activeUrl}
            onChangeUrl={() => console.log("unable to change url")}
            latest={video}
            course={course!}
          />
        )}
        {/* {video.stream_key && <VideoLinks latest={video} />} */}
      </Card>
       {video?.vid && <Engagement roomId={video?.vid} />}
    </Col>
  );
}

export default LiveStudio;
