import { Card } from 'antd'
import { getChatUrl } from '../../api';
import { useEffect } from 'react';

interface IProps {
    height?: number;
    videoId: string | undefined;
    token: string;
}

export default function StudioLiveChat({ height = 650, videoId, token }: IProps) {
    const CHAT_URL = getChatUrl(videoId!, token);
    if (!videoId) return <></>;
    return (
        <Card style={{height:window.innerHeight}}>
            <iframe
                title="KGS Player"
                height={window.innerHeight*0.9}
                frameBorder={0}
                style={{
                    borderRadius: 10,
                    overflow:'hidden',
                    position:'fixed',
                    width:window.innerWidth*0.37
                }}
                src={CHAT_URL}
            ></iframe>

        </Card>
    )
}
