import { io,  Socket } from 'socket.io-client';
import { IVideo } from '../interfaces';
import useSocketStore from '../store/socket';
import { BASE_URL } from '../api';

export default class socketIO {
	socket: Socket;

	constructor() {
		this.socket = io(BASE_URL);
		return this;
	}

	liveStarted(videoId: string, stream_key: string, fn: () => void) {
		this.socket.on(`${videoId}-liveStarted`, fn);
		this.socket.on(`${stream_key}-liveStarted`, fn);
	}
	liveEnded(
		videoId: string,
		stream_key: string,
		callback: (vdo: IVideo) => void
	) {
		this.socket.on(`${videoId}-liveEnded`, callback);
		this.socket.on(`${stream_key}-liveEnded`, callback);
	}
	liveEndedList(callback: (vdo: IVideo) => void) {
		this.socket.on(`liveEnded`, callback);
	}
	liveRendered(
		videoId: string,
		stream_key: string,
		fn: (video: IVideo) => void
	) {
		this.socket.on(`${videoId}-rendered"`, fn);
		this.socket.on(`${stream_key}-rendered"`, fn);
	}
	liveRenderedList(fn: (video: IVideo) => void) {
		this.socket.on(`rendered`, fn);
	}
	taskUpdated(
		videoId: string,
		stream_key: string,
		fn: (video: IVideo) => void
	) {
		this.socket.on(`task-${videoId}`, fn);
		this.socket.on(`task-${stream_key}`, fn);
	}
	taskListUpdated(fn: (video: IVideo) => void) {
		this.socket.on(`task-update`, fn);
	}
	streamingStarted(
		videoId: string,
		stream_key: string,
		fn: (video: IVideo) => void
	) {
		this.socket.on(`${videoId}-streamingStarted`, fn);
		this.socket.on(`${stream_key}-streamingStarted`, fn);
	}
	statistic(fn:(statistic:any) => void){
		this.socket.on(`statistics`, fn);
	}
}

export const useSocket = () => {
	const { socket } = useSocketStore();
	return socket;
};
