
const endpoints = {
  courseUrl:"https://www.khanglobalstudies.com/api/all-courses",
  adminBaseUrl: "https://api.khanglobalstudies.com/v1/studio/",
  // loginUrl: "https://admin2.khanglobalstudies.com/studio/login",
  get loginUrl() {
    return this.adminBaseUrl + "login";
  },
  playerUrl:"https://khanglobalstudies.com/",
  playerUrlNew:'https://web.kgs.ink/',
  baseUrl:"https://studio-api.kgs.live/",
  broadcastUrl:"rtmp://broadcast.kgs.live/live",
  rtmpURl:"https://stream.kgs.live",
};

export default endpoints