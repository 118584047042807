import { RouterProvider } from "@tanstack/react-router";
import { ConfigProvider } from "antd";
import React from "react";
import ReactDOM from "react-dom/client";
import "./routes";
import router from "./routes";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
