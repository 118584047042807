import {
  Button,
  Col,
  Descriptions,
  Drawer,
  Timeline,
  Tooltip,
  Modal,
} from "antd";
import useClipboard from "../utils";
import api, { BASE_URL, RTMP_SERVER } from "../api";
import { DataType, IVideo } from "../interfaces";
import { useEffect, useState } from "react";
import useCourseStore from "../store/course";
import { timeEnd } from "console";
import {
  CopyOutlined,
  DeleteOutlined,
  LoadingOutlined,
  RedoOutlined,
  ForwardOutlined,
} from "@ant-design/icons";
import { ICourse } from "../screens/list";
import moment from "moment";
import { message } from "antd";
import VideoProgres from "./videoProgres";

interface IProps {
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  video: IVideo;
  onDelete: (video: IVideo) => void;
}

function VideoDetailDrawer({
  drawerOpen,
  setDrawerOpen,
  video,
  onDelete = (video) => {},
}: IProps) {
  const { getCourse } = useCourseStore();
  const { copy } = useClipboard();
  const [course, setCourse] = useState<ICourse | null>(
    getCourse(video?.course_id)
  );
  const [open, setOpen] = useState(false);

  const handleDelete = async () => {
    const response = await api.delete(`/video/${video._id}`);
    onDelete(video);
    setDrawerOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleContinue = async () => {
    try {
      const response = await api.get(
        `resume-task?streamKey=${video.stream_key}`
      );
      message.success("Successfully Continued", 5);
    } catch (error) {
      message.error("Error While Continuing", 10);
    }
  };

  const handleReload = async () => {
    if (["live", "schedule"].includes(video.status)) {
      return message.error("You Can't Restart as video is not ended");
    }
    try {
      const res = api.get(`force-start/${video.stream_key}`);
      message.success("Successfully Restarted.");
    } catch (e) {
      message.error("Internal server error");
    }
  };

  if (!video) return null;

  const videoUrl = ["live", "rendering"].includes(video.status)
    ? `${RTMP_SERVER}/hls/${video.stream_key}.m3u8`
    : `${video.url}/${video.stream_key}.m3u8`;
    
  const cdnUrl = ["live", "rendering"].includes(video.status)
    ? `${localStorage.getItem("live_CDN")}/hls/${video.stream_key}.m3u8`
    : `${video.cdnUrl}/${video.stream_key}.m3u8`;

  return (
    <Drawer
      title={`#${video.vid}`}
      extra={
        <>
          <Button
            onClick={() => {
              Modal.warning({
                title: "Warning",
                content: "Are You Sure You Want to Restart Forcefully?",
                okText: "Yes",
                cancelText: "No",
                onOk: handleReload,
                onCancel: handleCancel,
              });
            }}
            style={{ marginRight: 10 }}
            icon={<RedoOutlined />}
            size="middle"
          />
          <Button
            onClick={() => {
              Modal.confirm({
                title: "Confirm",
                content: "Are You Sure You Want to Delete?",
                okText: "Yes",
                cancelText: "No",
                onOk: handleDelete,
                onCancel: handleCancel,
              });
            }}
            style={{ marginLeft: 5, padding: 0 }}
            icon={<DeleteOutlined />}
          />
        </>
      }
      placement="right"
      width={"50%"}
      onClose={() => setDrawerOpen(false)}
      open={drawerOpen}
    >
      <Col offset={1} md={24} sm={24} xs={24}>
        <Descriptions layout="vertical">
          <Descriptions.Item label="Title">{video.title}</Descriptions.Item>
        </Descriptions>
        <Descriptions layout="vertical">
          <Descriptions.Item label="Scheduled For">
            {moment(video.schedule_at).format("DD-MM-YY HH:SS")}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions layout="vertical">
          {course && (
            <Descriptions.Item label="Course">
              {course?.title}
            </Descriptions.Item>
          )}
        </Descriptions>
        <Descriptions layout="vertical">
          <Descriptions.Item label="Video Url">
            {video.status == "schedule" ? (
              "url will be available once video will live"
            ) : (
              <>
                <span>{videoUrl}</span>
                <Tooltip title="Click to copy">
                  <Button
                    onClick={() => copy(videoUrl, "Url copied")}
                    style={{ marginLeft: 5, padding: 0 }}
                    icon={<CopyOutlined style={{ margin: 0 }} />}
                  />
                </Tooltip>
              </>
            )}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions layout="vertical">
          <Descriptions.Item label="CDN Url">
            {video.status == "schedule" ? (
              "url will be available once video will live"
            ) : (
              <>
                <span>{cdnUrl}</span>
                <Tooltip title="Click to copy">
                  <Button
                    onClick={() => copy(cdnUrl, "Url copied")}
                    style={{ marginLeft: 5, padding: 0 }}
                    icon={<CopyOutlined style={{ margin: 0 }} />}
                  />
                </Tooltip>
              </>
            )}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions layout="vertical">
          {course && (
            <Descriptions.Item label="Course">
              {course?.title}
            </Descriptions.Item>
          )}
        </Descriptions>
      </Col>
      <Col
        style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        offset={1}
        md={24}
        sm={24}
        xs={24}
      >
        <div>Video Rendring Status</div>

        <Button
          onClick={handleContinue}
          style={{ marginLeft: 5 }}
          icon={<ForwardOutlined />}
          size="middle"
        />
      </Col>
      <Col offset={1} md={24} sm={24} xs={24}>
        <VideoProgres video={video} />
      </Col>
    </Drawer>
  );
}

export default VideoDetailDrawer;
