import { DatePicker, DatePickerProps, Input, Select, Space } from "antd";
import { status, } from "../../utils/constants";
import useCourseStore from "../../store/course";

export interface IQuery {
  title: string;
  vid?: string;
  status?: string;
  courseId?: string;
  date?: string;
  page:number;
  pageSize:number
  videoType:'yt'|''
}

interface IProps {
  query: IQuery;
  setQuery: (query:object)=>void
}

function HistoryFilter({ query, setQuery }: IProps) {
    const { courses } = useCourseStore();

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setQuery({date:dateString})
  };
  const handleSelectCourse = (courseId: string) => {
    setQuery({courseId})
  };

  return (
    <Space>
      <DatePicker onChange={onDateChange} />
      <Select
        showSearch
        allowClear
        onClear={() =>
          setQuery({courseId:''})
        }
        style={{ width: 220 }}
        fieldNames={{ label: "title", value: "id" }}
        placeholder="Select the course"
        onSearch={(value) => {}}
        options={courses}
        onSelect={handleSelectCourse}
        filterOption={(input, option) =>
          (option?.title ?? "").toLowerCase().includes(input.toLowerCase())
        }
      />
      <Select
        placeholder="Select Status"
        allowClear
        onClear={() =>setQuery({status:''})}
        options={status}
        onSelect={(value) =>setQuery({status:value})}
      />
      <Input
        onChange={(e) =>setQuery({title:e.target.value})}
        placeholder="Enter Title to Search"
      />
      <Input
        onChange={(e) =>setQuery({vid:e.target.value})}
        placeholder="Enter video id to Search"
      />
    </Space>
  );
}

export default HistoryFilter;
