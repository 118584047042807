import React from 'react';
import { Chart, AxisOptions } from 'react-charts';

const data = {
	label: 'Student Count',
	data: [
		{
			date: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
			stars: 0,
		},
	],
};
type MyDatum = { date: any; stars: number };
export default function AudienceChart({ count }: { count: MyDatum }) {
	const [Data, setData] = React.useState(data);

	const primaryAxis = React.useMemo(
		(): AxisOptions<MyDatum> => ({
			getValue: (datum) => datum.date,
		}),
		[]
	);
	React.useEffect(() => {
		if (Data.data.length > 10) {
			const newData = { ...Data };
			newData.data.shift();
			setData(newData);
		}
	}, [Data]);

	React.useEffect(() => {
		if (count) {
			const newData = { ...Data };
			newData.data.push(count);
			setData(newData);
		}
	}, [count]);

	const secondaryAxes = React.useMemo(
		(): AxisOptions<MyDatum>[] => [
			{
				getValue: (datum) => datum.stars,
				stacked: true,
				elementType: 'line',
			},
		],
		[]
	);

	return (
		<div style={{ height: 300, width: 500 }}>
			<Chart
				options={{
					data: [Data],
					primaryAxis,
					secondaryAxes,
				}}
			/>
		</div>
	);
}
