import { useEffect, useState } from "react"

export function useStorage<T>(key: string, initialValue?: T) {

  const [value, setValue] = useState<T>(() => {
    const valueFromStorage = getItem(key)

    if (
      typeof initialValue === "object" &&
      !Array.isArray(initialValue) &&
      initialValue !== null
    ) {
      return {
        ...initialValue,
        ...valueFromStorage,
      }
    }

    return valueFromStorage || initialValue
  })
  function getItem(key: string) {
    const item = localStorage.getItem(key)
    if (item === null) return undefined

    if (item === "null") return null
    if (item === "undefined") return undefined

    try {
      return JSON.parse(item)
    } catch {}

    return item
  }
  function setItem(key: string, value: any) {
    if (value === undefined) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, JSON.stringify(value))
    }
  }

  useEffect(() => {
    setItem(key, value)
  }, [key, value])

  return [value, setValue] as const
}

