import { Card, Col, Image, Row } from "antd";
import { useEffect, useState } from "react";
import { IVideo } from "../interfaces";
import api from "../api";
import useCourseStore from "../store/course";
import { ICourse } from "./list";
import { useParams } from "@tanstack/react-router";
import StudioLiveChat from "../component/Studio/StudioLiveChat";
import { useSocket } from "../utils/socket";
import Engagement from "../utils/engagement";
import LiveStudio from "../component/Studio/liveStudio";
import YTVideo from "../component/Studio/YTVideo";
import Rendered from "../component/Studio/Rendered";
import { useStorage } from "../hooks";

const AUTH_TOKEN = localStorage.getItem("AUTH_TOKEN");

export default function Studio() {
  const params = useParams({ from: "" });
  const [latest, setLatest] = useState<IVideo>({ title: "" } as IVideo);
  const [course, setCourse] = useState<ICourse>();
  const [player, setPlayer] = useStorage<"old" | "new">('player',"new");
  const { getCourse } = useCourseStore();
  const [iframeKey, setIframeKey] = useState(0);
  const socket = useSocket();
  const getLatest = async () => {
    try {
      const { data } = await api.get<IVideo>(
        `video/${params?.slug || "getlatest"}`
      );
      setLatest(data);
      setCourse(getCourse(data.course_id));
    } catch (error: any) {}
  };

  const registerSocket = () => {
    if (!latest._id) return;
    socket.liveEnded(latest._id, latest.stream_key, (video) => {
      setLatest(video);
    });
    socket.streamingStarted(latest._id, latest.stream_key, (video) => {
      setTimeout(() => {
        setIframeKey((key) => key + 1);
      }, 1000);
    });
    socket.taskUpdated(latest._id, latest.stream_key, (video) =>
      setLatest(video)
    );
    socket.liveRendered(latest._id, latest.stream_key, (video) =>
      setLatest(video)
    );
  };

  useEffect(() => {
    getLatest();
    registerSocket();
  }, [latest._id]);

  if (latest.videoType === "yt") return <YTVideo video={latest} />;
  if (latest.status === "rendered")
    return (
      <Rendered
        player={player}
        setPlayer={setPlayer}
        course={course}
        video={latest}
      />
    );

  return (
    <Card style={{overflow:'hidden'}} loading={latest ? false : true} className="bg-muted">
      <Row gutter={16} style={{overflow:'hidden'}}>
        <LiveStudio
          player={player}
          setPlayer={setPlayer}
          course={course!}
          frameKey={iframeKey}
          video={latest}
          onDone={(video) => setLatest(video)}
        />
        {latest.status === "schedule" ? (
          <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
            <Image src="/instruction.jpg" />
          </Col>
        ) : (
          <Col style={{}} xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
            {AUTH_TOKEN && (
              <StudioLiveChat
                videoId={latest?.vid?.toString()}
                token={AUTH_TOKEN}
              />
            )}
          </Col>
        )}
      </Row>
    </Card>
  );
}
