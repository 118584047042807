import { Status } from "../interfaces";

export const status = [
    {
      key: "Schedule",
      value: "schedule",
      label: "Schedule",
    },
    {
      key: "Live",
      value: "live",
      label: "Live",
    },
    {
      key: "Rendering",
      value: "rendering",
      label: "Rendering",
    },
    {
      key: "Ended",
      value: "ended",
      label: "Ended",
    },
    {
      key: "Rendered",
      value: "rendered",
      label: "Rendered",
    },
    {
      key: "in-queue",
      value: "in-queue",
      label: "In Queue",
    },
  ]

 export  const statusColor: Record<Status, string> = {
    schedule: "",
    live: "green",
    rendering: "warning",
    rendered: "processing",
    ended: "purple",
    'in-queue':'magenta'
  };
