import axios from 'axios';
import { useEffect, useState } from 'react';
import api, { ADMIN_BASE_URL } from '../api';
import {
	Space,
	Card,
	Table,
	Button,
	Badge,
	Image,
	Select,
	DatePicker,
	DatePickerProps,
	Checkbox,
	Input,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { VideoCameraFilled } from '@ant-design/icons';
import moment from 'moment';
import { Link,  useNavigate } from '@tanstack/react-router';
import { ISchedule,  IVideo } from '../interfaces';
import { message } from 'antd';
import useCourseStore from '../store/course';
import { ICourse } from './list';


export default function Schedules() {
	const [loading, setloading] = useState(true);
	const [resData, setResData] = useState([]);
	const [filteredCourses, setFilteredCourses] = useState<ICourse[]>([]);
	const { courses } = useCourseStore();
	const [schedules, setSchedules] = useState<ISchedule[]>([]);
	const [multiples, setMultiples] = useState<IVideo[]>([]);
	const [filter, setFilter] = useState({
		course: 0,
		date: '',
		videoId: '',
	});
	const navigate = useNavigate({ from: '/schedules' });
	const UN_AUTHENTICATED_CODE = 401;
	const getSchedules = async () => {
		try {
			const { data } = await axios.get(ADMIN_BASE_URL + `schedules`, {
				headers: {
					Authorization:
						'Bearer ' + localStorage.getItem('AUTH_TOKEN'),
				},
			});
			setloading(false);
			setResData(data);
			setSchedules(data);
		} catch (e: any) {
			if (e?.response?.status === UN_AUTHENTICATED_CODE) {
				localStorage.removeItem('AUTH_TOKEN');
				navigate({ to: '/login' });
			}
		}
	};
	useEffect(() => {
		if (filter.videoId) {
			setSchedules(
				resData.filter((schedule: any) => {
					return String(schedule.id).match(filter.videoId);
				})
			);
			return;
		}
		if (filter.date === '' && filter.course == 0) {
			return setSchedules(resData);
		}
		setSchedules(
			resData.filter((data: any) => {
				if (filter.course && filter.date)
					return (
						data.course.id === filter.course &&
						moment(data?.published_at).isSame(filter.date, 'day')
					);
				return (
					data.course.id === filter.course ||
					moment(data?.published_at).isSame(filter.date, 'day')
				);
			})
		);
	}, [filter]);

	useEffect(() => {
		setFilteredCourses(courses);
	}, [courses]);

	useEffect(() => {
		getSchedules();
	}, []);

	const handleScheduleLive = async (record: any) => {
		const bucketId = localStorage.getItem('default_bucket');
		if (!bucketId)
			return message.error('Please select bucket for record video');
		const payload = {
			vid: record.id,
			title: record.name,
			course_name: record.course.title,
			course_id: record.course.id,
			lesson_id: record.name,
			bucket: bucketId,
		};
		try {
			const { data } = await api.post<IVideo>('video', payload);
			navigate({ to: `/studio/${data._id}` });
		} catch (e: any) {
			console.log({ e });
		}
	};

  const columns: ColumnsType<ISchedule> = [
    {
      render: (_, record: any) => (
        <Checkbox
          checked={!!multiples.find((mul) => mul.vid === record.id)}
          onChange={(arg) => {
            const bucketId = localStorage.getItem("default_bucket");
            const selectedVideo: any = {
              key: record.key,
              vid: record.id,
              title: record.name,
              course_name: record.course.title,
              course_id: record.course.id,
              lesson_id: record.name,
              bucket: bucketId,
            };
            const checked = arg.target.checked;
            if (checked === true) {
              return setMultiples((multiples) => [...multiples, selectedVideo]);
            }
            return setMultiples((multiples) =>
              multiples.filter((item: any) => item.vid !== selectedVideo.vid)
            );
          }}
        ></Checkbox>
      ),
    },
    {
      title: "S No",
      dataIndex: "index",
      key: "index",
      render: (a, b, index) => {
        return <p>{index + 1}</p>;
      },
	  responsive: ['lg','md'],
    },
    {
      title: "VID",
      dataIndex: "id",
      key: "id",
      align:"center",
	  responsive: ['lg','md'],
    },
    {
      title: <div style={{ textAlign: 'center' }} >Topic</div>,
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <Space size="middle">
          <Image
            src={
              record.thumb || "./default_image.jpg"
            }
            height="40px"
            width="auto"
          />
          <p style={{ textAlign: "center" }}>{record.name}</p>
        </Space>
      ),
     
    },
    {
      title: "Course",
      dataIndex: "course",
      key: "course",
      render: (course) => <a>{course.title}</a>,
      align:"center",
	  responsive: ['lg'],
    },
    {
      title: "Publish At",
      dataIndex: "published_at",
      key: "published_at",
      render: (published_at) => (
        <Badge>{moment(published_at).format("DD-MM-YY HH:SS")}</Badge>
      ),
      align:"center",
	  responsive: ['lg','md'],
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleScheduleLive(record)}>
            <VideoCameraFilled color="red" />
          </Button>
        </Space>
      ),
      align:"center"
    },
  ];

	const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
		setFilter((filter) => ({ ...filter, date: dateString }));
	};

	const handleSelectCourse = (courseId: number) => {
		setFilter((filter) => ({ ...filter, course: courseId }));
	};

	const selectByVideoId = (vid: string) => {
		console.log(vid);
		setFilter((filter) => ({ ...filter, videoId: vid }));
	};

	const handleBulkLive = async () => {
		try {
			const { data } = await api.post('/video/multiples', {
				data: multiples,
			});
			navigate({ to: '/list' });
		} catch (error: any) {
			message.error('something went wrong');
		}
	};

	return (
		<>
			<Card
				title="Schedules Classes"
				extra={
					<Space>
						{multiples.length > 0&& (
							<Button onClick={handleBulkLive}>
								<VideoCameraFilled />
								Bulk Go Live
							</Button>
						)}
						<Space style={{ margin: 20 }}>
							<Input
								onChange={(e) =>
									selectByVideoId(e.target.value)
								}
								placeholder="Enter video id to Search"
							/>
							<Select
								showSearch
								allowClear
								onClear={() =>
									setFilter((f) => ({ ...f, course: 0 }))
								}
								fieldNames={{ label: 'title', value: 'id' }}
								placeholder="Select the course"
								onSearch={(value) => {}}
								options={filteredCourses}
								onSelect={handleSelectCourse}
								filterOption={(input, option) =>
									(option?.title ?? '')
										.toLowerCase()
										.includes(input.toLowerCase())
								}
							/>
							<DatePicker
								onChange={onChangeDate}
								picker="date"
							/>
						</Space>
						<Link
							to="/go-live"
							from="/"
						>
							<Button danger={true}>
								<VideoCameraFilled />
								Go Live
							</Button>
						</Link>
					</Space>
				}
			>
				<Table
					columns={columns}
					dataSource={schedules}
					loading={loading}
				/>
			</Card>
		</>
	);
}
