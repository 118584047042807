import {
  ControlOutlined,
  DesktopOutlined,
  ScheduleOutlined,
  SettingOutlined,
  LogoutOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import { Link } from "@tanstack/react-router";
import { Menu, Modal } from "antd";
import Sider from "antd/es/layout/Sider";
import { MenuProps } from "rc-menu";
import { useState } from "react";
import styled from "styled-components";

type MenuItem = Required<MenuProps>["items"][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}


const handleOk = () => {
  localStorage.removeItem("AUTH_TOKEN");
  window.location.reload();
};


const items: MenuItem[] = [
  getItem(
    <Link to="/" >
      Dashboard
    </Link>,
    "",
    <DashboardOutlined />
  ),
  getItem(
    <Link to="/schedules" from="/">
      Schedules
    </Link>,
    "schedules",
    <ScheduleOutlined />
  ),
  getItem(
    <Link to="/list" from="/">
      History
    </Link>,
    "list",
    <DesktopOutlined />
  ),
  getItem(
    <Link to="/youtube" from="/">
      Youtube
    </Link>,
    "youtube",
    <DesktopOutlined />
  ),
  getItem(
    <Link to="/config" from="/">
      Configuration
    </Link>,
    "config",
    <ControlOutlined />
  ),
  getItem(
    <Link to="/tools" from="/">
      Utility
    </Link>,
    "tools",
    <SettingOutlined />
  ),
  getItem(
    <p
      onClick={() => {
        Modal.confirm({
          title: "Confirm",
          content: "Are You Sure You Want to Logout?",
          okText: "Yes",
          cancelText: "No",
          onOk: handleOk,
        });
      }}
    >
      Logout
    </p>,
    "6",
    <LogoutOutlined />
  ),
];
export default function SideBar({ defaultSelectedKeys }: any) {
  const [collapsed, setCollapsed] = useState(false);
  const location =window.location.pathname
  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{ background: "#1D1F5D" }}
    >
      <LogoContainer>
        {collapsed ? (
          <img
            height={40}
            alt="KGS Studio"
            src={
              "https://kgs.nyc3.cdn.digitaloceanspaces.com/uploads/hbC0ocuRD41Btvm2JRqK9gkjCxMPkH2YIXShLG5Q.png"
            }
          />
        ) : (
          <>
            <img
              height="auto"
              src="/kgs-studio.png"
              alt="KGS Studio"
              width="80%"
            />
          </>
        )}
      </LogoContainer>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[location.split('/')[1]=='studio'?'list':location.split('/')[1]]}
        defaultSelectedKeys={["1"]}
        items={items}
        style={{ background: "#1D1F5D" }}
      />
    </Sider>
  );
}

const LogoContainer = styled.div`
  font-size: 25px;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  color: white;
`;
