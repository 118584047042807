import { Button, Timeline } from "antd";
import { useEffect, useState } from "react";
import { IVideo } from "../interfaces";
import api from "../api";
import { LoadingOutlined } from "@ant-design/icons";

 function VideoProgress({video}:{video:IVideo}){
  const [timelines, setTimelines] = useState<any>(null);

  const handleResumeVideo = async (taskType: IVideo["tasks"][0]["type"]) => {
     await api.post(`/resume/${video._id}`, {
      taskType,
    });
  };
  useEffect(() => {
    const tmls = (video.tasks).map((task) => {
        const color = task.status == "success"
        ? "green"
        : task.status == "failed"
        ? "red" 
        : task.status == "active"
        ? "blue"
        : "gray"

        console.log({color})


      return {
        dot:task.status=='active'?<LoadingOutlined />:null,
        color,
        children:
          task.status == "failed" ? (
            <>
              <p>{task.message}</p>
              <Button
                onClick={() => handleResumeVideo(task.type)}
                title="Try Again"
              >
                Try Again
              </Button>
            </>
          ) : (
            task.message
          ),
      };
    });
    setTimelines(tmls);
  }, [video.tasks]);

    return (
        <Timeline style={{ marginTop: 30 }} items={timelines} />
    )
}

export default VideoProgress