import { create } from 'zustand'
import { IConfig } from '../interfaces'

export type IBucketStore = {
    buckets:IConfig[],
    error:string
    setError:(error:string)=>void
    createBucket:(bucket:IConfig[])=>void,
    addBucket: (newBucket: IConfig) => void,
    removeBucket: (id: string) => void,
    emptyBucket: () => void
}

const useBucketStore = create<IBucketStore>((set) => ({
    buckets: [],
    createBucket: (buckets:IConfig[]) => set(() =>({ buckets})),
    error:'',
    setError:(error:string)=>set((state:IBucketStore) => ({error:error})),
    addBucket: (newBucket:IConfig) => set((state:IBucketStore) => ({buckets:[...state.buckets, newBucket]})),
    removeBucket: (id:string) => set((state:IBucketStore) => ({buckets:state.buckets.filter(b => b._id !== id)})),
    emptyBucket: () => set(() =>({buckets:[]}))
}))

export default useBucketStore