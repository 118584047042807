import { Button, Modal, message } from "antd";
import { IVideo } from "../../interfaces";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useState } from "react";
import api from "../../api";

interface IProps {
  video: IVideo;
  onDone: (video: IVideo) => void;
}

export default function ActionButton({ video ,onDone}:IProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = async () => {
    setIsLoading(true);
    let url = "";
    if (video.status == "schedule") {
      url = "go-live";
    }
    if (video.status == "ended"|| video.status=='live') {
      url = "end-live";
    }
    try {
      const { data } = await api.get<IVideo>(url, {
        params: {
          videoId: video._id,
          streamKey: video.stream_key,
        },
      });
      onDone(data);
      setIsLoading(false);
      return;
    } catch (error: any) {
      console.log({error})
      // message.error(error?.response?.data.message||'Server Error');
      setIsLoading(false);
    }
  };
  const showConfirmation = () => {
    Modal.confirm({
      title: "Are you sure you want to do this?",
      icon: <ExclamationCircleFilled />,
      content: "",
      onOk() {
        handleUpdate();
      },
      onCancel() {},
    });
  };

  if (video.status == "schedule")
    return (
      <Button onClick={showConfirmation} type="primary" loading={isLoading}>
        Start streaming{" "}
      </Button>
    );
  if (video.status == "ended"||video.status=='live') {
    return <Button onClick={showConfirmation} type="primary" loading={isLoading}>
      End Live
    </Button>;
  }
  return <div />;
}
