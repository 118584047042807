import { Button, Card, Form, Input, Select, TimePicker, message } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useNavigate } from "@tanstack/react-router";
import { ICourse } from "./list";
import useBucketStore from "../store";
import useCourseStore from "../store/course";
import { IVideo } from "../interfaces";
import api from "../api";

type TLesson = { label: string; value: string };

export default function GoLive() {
  const [title, setTitle] = useState("");
  const [filteredCourses, setFilteredCourses] = useState<ICourse[]>([]);
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [selectedCourse, setSelectedCourse] = useState({} as ICourse);
  const [lessons, setLessons] = useState<TLesson[]>([]);
  const [selectedLesson, setSelectedLesson] = useState("");
  const [bucketId, setBucketId] = useState("");
  const { buckets } = useBucketStore();
  const navigate = useNavigate({ from: "/list" });
  const { courses } = useCourseStore();
  const [form] = useForm();

  useEffect(() => {
    setFilteredCourses(courses);
  }, [courses]);

  const handleSelectCourse = (value: string) => {
    setSelectedCourseId(value);
    const filter = filteredCourses.find((course) => course.id === value);
    setSelectedCourse(filter!);
    const lessons = (filter?.lessons || []).map((lesson) => ({
      label: lesson,
      value: lesson
        .split(" ")
        .map((a) => a[0])
        .join(""),
    }));
    setLessons(lessons);
  };

  const handleScheduleClass = async () => {
    const vid = "studio-" + (Math.random() + 1).toString(36).substring(2);
    const { data } = await api.post<IVideo>("video", {
      title,
      vid,
      course_name: selectedCourse.title,
      course_id: selectedCourseId,
      lesson_id: selectedLesson,
      bucket: bucketId,
    });
    form.resetFields();
    navigate({ to: `/studio/${data._id}` });
  };

  return (
    <Card title="+ Schedule Live Class" type="inner">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleScheduleClass}
        onFinishFailed={() => {}}
      >
        <Form.Item
          label="Class Title"
          name="tile"
          rules={[
            {
              required: true,
              message: "Please give title to your config!",
            },
          ]}
        >
          <Input onChange={(event) => setTitle(event.target.value)} />
        </Form.Item>
        <Form.Item label="Select Course" name="course">
          <Select
            showSearch
            fieldNames={{ label: "title", value: "id" }}
            placeholder="Select the course"
            onSelect={handleSelectCourse}
            onSearch={(value) => {}}
            options={filteredCourses}
            filterOption={(input, option) =>
              (option?.title ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item label="Select lesson" name="lesson">
          <Select
            showSearch
            placeholder="select lesson"
            onChange={(val) => {
              // console.log("value changed", { val });
            }}
            onSelect={(value) => {
              setSelectedLesson(value);
            }}
            onSearch={(value) => {}}
            options={lessons}
          />
        </Form.Item>
        <Form.Item label="Select bucket" name="bucket">
          <Select
            showSearch
            placeholder="select bucket"
            fieldNames={{ label: "title", value: "_id" }}
            onChange={(val) => {
              // console.log("value changed", { val });
            }}
            onSelect={(value) => {
              setBucketId(value);
            }}
            onSearch={(value) => {}}
            options={buckets}
          />
        </Form.Item>
        <Form.Item label="Scheduled Time" name="time">
          <TimePicker onChange={(event) => console.log(event?.get("d"))} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large">
            Go Live
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
