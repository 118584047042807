import {
  Card,
  Tabs,
  TabsProps,
  message,
} from "antd";
import StudioSettingsCard from "../component/Cards/StudioSettingsCard";
import BucketDetailCard from "../component/Cards/BucketDetailCard";
import { IConfig } from "../interfaces";
import api from "../api";
import { useEffect } from "react";
import { useStorage } from "../hooks";
import useBucketStore from "../store";

export default function Config() {
 
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Cloud Bucket Configurations',
      children: <BucketDetailCard />,
    },
    {
      key: '2',
      label: 'Studio Settings',
      children: <StudioSettingsCard />
    },
  ];

  return (
    <div>
      <Card title="Studio Configuration" type="inner">
        <Tabs defaultActiveKey="1" items={items} />
      </Card>
    </div>
  );
}

